<template>
    <div class="container">

 <v-btn  @click="$router.go(-1)"  > Back  </v-btn>
<div class="row">


    <div class="col-md-5">


 <v-card
    max-width="375"
    class="mx-auto pl-6"
  >

    <v-card-title class="text-align-center"> Company : {{signupVendor.companyName}}  </v-card-title>

    <v-list class="align-end" two-line>
      <v-list-item>
        <v-list-item-action>
          Contact
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title >{{signupVendor.firstName}} {{signupVendor.lastName}}</v-list-item-title>
        </v-list-item-content>



   
      </v-list-item>

        <v-list-item>
        <v-list-item-action>
          Status
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title >{{signupVendor.status}}</v-list-item-title>
        </v-list-item-content>


   
      </v-list-item>
  <v-list-item>
        <v-list-item-action>
          Signup Date
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title >{{signupVendor.created_at | date_format}}</v-list-item-title>
        </v-list-item-content>



   
      </v-list-item>



     <v-list-item>
        <v-list-item-action>
          Contact
        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title >{{signupVendor.email}}</v-list-item-title>
        </v-list-item-content>



   
      </v-list-item>





     <v-list-item>
        <v-list-item-action>
Website        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>{{signupVendor.website}}</v-list-item-title>
        </v-list-item-content>




   
      </v-list-item>

     <v-list-item>
        <v-list-item-action>
Yearly Sales        </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>{{signupVendor.yearlySales}}</v-list-item-title>
        </v-list-item-content>




   
      </v-list-item>

     <v-list-item>
        <v-list-item-action>
Category       </v-list-item-action>

        <v-list-item-content>
          <v-list-item-title>{{signupVendor.category}}</v-list-item-title>
        </v-list-item-content>




   
      </v-list-item>



    

      <v-divider inset></v-divider>



      <v-list-item>
<v-list-item-content>

 <div class="display-2">   About the Buyer:  </div> <br>
{{signupVendor.about}}
          
</v-list-item-content>

        
      </v-list-item>

    </v-list>
  </v-card>





    </div>

    <div class="col-md-7">

        <h2>  Create a new Partner </h2>



<b-form class="form" @submit.stop.prevent="createCompany">


<v-card>

    <h5>Search to see if  Partner Exists </h5>   </v-card-text>


    <v-card-text>

            <v-autocomplete
        v-model="company"
        :items="companies"
        :search-input.sync="search"
        color="white"
        hide-no-data
        hide-selected
        item-text="company_name"
        item-value="id"
        placeholder="Start typing to Search"
        prepend-icon="mdi-database-search"
        return-object
      ></v-autocomplete>
    </v-card-text>

    </v-card>



 <v-card class=""
    light
  >

    <v-card-text>


  <v-card-title class="headline  lighten-3">
<strong>    <span>Create a New Partner  </span></strong>  </v-card-title>

  <v-select
          v-model="type"
          :items="typeItems"
          label="Choose Type of Company"
          disabled
          class="mt-7 mb-7"

        ></v-select>

      
                            <div class="form-group row">
                                <label  class="col-3 col-form-label text-dark">Contact Email </label>
                                <div class="col-9">
                                    <v-text-field
                                    
                                      v-model="$v.email.$model"
                                      :state="validateState('email')"
                                      aria-describedby="input-1-live-feedback"
                                     
                                    ></v-text-field>

                                    <b-form-invalid-feedback id="input-1-live-feedback">
                                      Email is required and a valid email.
                                    </b-form-invalid-feedback>
                                </div>
                            </div>


                             <div class="form-group row">
                                <label  class="col-3 col-form-label text-dark">Company Name </label>
                                <div class="col-9">
                                    <b-form-input
                                      class="form-control"
                                      v-model="$v.company_name.$model"
                                      :state="validateState('company_name')"
                                      aria-describedby="input-2-live-feedback"
                                      placeholder=""
                                    ></b-form-input>

                                    <b-form-invalid-feedback id="input-2-live-feedback">
                                      Company name is required.
                                    </b-form-invalid-feedback>
                                </div>
                            </div>
                               <div class="form-group row">
                                <label  class="col-3 col-form-label text-dark">Website </label>
                                <div class="col-9">
                                    <b-form-input
                                      class="form-control"
                                      v-model="$v.website.$model"
                                      :state="validateState('website')"
                                      aria-describedby="input-2-live-feedback"
                                      placeholder=""
                                    ></b-form-input>

                                    <b-form-invalid-feedback id="input-2-live-feedback">
                                      website is required.
                                    </b-form-invalid-feedback>
                                </div>
                            </div>


                                 
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="grey darken-3"
        type="submit"
      >
        Create
        <v-icon right>mdi-account-circle</v-icon>
      </v-btn>
    </v-card-actions>
    
  </v-card>
</b-form>


    </div>

</div>









    </div>
</template>



<script>
var moment = require("moment");
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import gql from "graphql-tag";

import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";


export default {

      mixins: [validationMixin],

  data() {
    return {
      signup: {},
      moment: moment,
      company: null,
      search: "",
      companies: [],

         typeItems: [
        {
          text: 'Seller',
          value: 'supplier'
        }
      ],
    
      type: "supplier",
      email: "",
      website: "",
      company_name: "",
      api_url: process.env.VUE_APP_STRAPI_API_URL,
      routeParam: this.$route.params.id
    };
  },


  methods: {
    ...mapActions([
        'submitCompany',
        'getCompanyTotalCount',
        'submitBusinessProfile',
        'submitProfile'
    ]),
    validateState(name) {
			const { $dirty, $error } = this.$v[name];
			return $dirty ? !$error : null;
		},
    createCompany(e) {
      e.preventDefault();
			this.$v.$touch();
			if (this.$v.$anyError) {
				return;
      }
      
      this.getCompanyTotalCount({
        params: "company_name="+ this.company_name
      })
      .then((res) => {
        if(res.data == 0) {
          if(this.type == 'seller') {
            this.submitBusinessProfile({
              company_name: this.company_name
            })
            .then((resp) => {
              this.submitCompany({
                company_name: this.company_name,
                email: this.email,
                type: this.type,
                website: this.website,
                business_profile: resp.data.id
              })
              .then(() => {
                Swal.fire({
                  title: "",
                  text: "The company has been successfully created!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary"
                });
                location.reload();
              });
            });
          } else {
            this.submitProfile({
              Name: this.company_name
            })
            .then((resp) => {
              this.submitCompany({
                company_name: this.company_name,
                email: this.email,
                type: this.type,
                website: this.website,
                profile: resp.data.id
              })
              .then(() => {
                Swal.fire({
                  title: "",
                  text: "The company has been successfully created!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary"
                });
                location.reload();
              });
            });
          }
        }
        else {
          this.$bvToast.toast('Company with the same name already exists!', {
            title: 'An Error Occured!',
            variant: 'danger',
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: true
          });
        }
      })
      .catch(() => {
        this.isConnecting = false;
        this.$bvToast.toast('An Error Occured!', {
          title: 'Error',
          variant: 'danger',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: true
        });
      })
    }
  },

    filters: {
    date_format: function(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
    fullName(user) {
      return (user.FirstName || "") + " " + (user.LastName || "");
    }
  },


    validations: {
		email: {
			required,
			email
    },
    	website: {
			required
    },
    company_name: {
      required
    }
  },

  apollo: {
    signupVendor: {
      query: gql`
        query signupVendors($id: ID!) {
          signupVendor(id: $id) {
            
            id
        status
    companyName
      firstName
        created_at
      lastName
      email
      website
      category
      yearlySales
      about
        
          }
        }
      `,
      variables() {
        return {
          id: this.routeParam
        };
      }
    },
    companies: {
      query: gql`
        query Companies($search: String) {
          companies(
            where: {
              company_name_contains: $search
            }) {
            id
            company_name
          }
        }
      `,
      variables() {
        return {
          search: this.search == null ? '' : this.search
        }
      },
    }
  }
};
</script>

